<div class="header animate__animated animate__fadeIn">
    <h1>Ng-Checkers</h1>
    <h2><i class="fab fa-angular"></i>&nbsp;Powered by Angular</h2>
</div>

<router-outlet></router-outlet>

<p class="footer animate__animated animate__fadeIn">
    Ng-Checkers by <a href="http://alexsineath.com">Alex Sineath</a> | 
    <i class="fab fa-angular"></i>&nbsp;Powered by Angular | 
    <i class="fab fa-github"></i>&nbsp;<a href="https://github.com/asineath/ngcheckers" target="_blank">View Source on GitHub</a><br>
    <a href="http://www.wcdf.net/rules.htm" target="_blank">World Checkers and Draughts Federation Rules</a> |
    <a href="https://www.vecteezy.com/free-vector/80s-background" target="_blank">80s Background Vector by Vecteezy</a>
</p>

